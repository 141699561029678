export enum LoadingStates {
	IDLE = 'idle',
	LOADING = 'loading',
	LOADED = 'loaded',
	ERROR = 'error',
}

export interface LoaderState<T, D> {
	state: LoadingStates;
	data?: T;
	error?: D;
}
