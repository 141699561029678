export class ApiErrorHelper {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	static getError<T>(errorResponse: any, definedErrors: any): T {
		try {
			let error: T = definedErrors['UNKNOWN'];
			if (errorResponse.error?.errors?.length) {
				error =
					errorResponse.error.errors[
						errorResponse.error.errors.length - 1
					].code;
			}
			if (
				Object.values(definedErrors).find(
					(errorCode) => error === errorCode,
				)
			) {
				return error;
			}
			return 'ERROR_UNKNOWN' as T;
		} catch (e) {
			return 'ERROR_UNKNOWN' as T;
		}
	}
}
